import { forwardRef, useState, type InputHTMLAttributes } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import {
  Text,
  textSizeClasses,
} from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  unit?: string;
  isHiddenMode?: boolean;
  unitClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, unit, isHiddenMode = false, unitClassName, ...props },
    ref,
  ) => {
    const [valueIsDisplay, setValueIsShow] = useState<boolean>(false);

    return (
      <div className="relative">
        <input
          type={isHiddenMode ? (valueIsDisplay ? "text" : "password") : type}
          className={cn(
            "flex w-full rounded-lg border border-new-dark-4 bg-new-light-4 py-3 pl-3 pr-8 text-new-dark-0 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-new-dark-3 focus-visible:border-new-primary-1 focus-visible:outline-none  disabled:cursor-not-allowed disabled:bg-new-light-3 disabled:text-new-dark-3",
            textSizeClasses.bodyRegular,
            className,
          )}
          ref={ref}
          {...props}
        />
        {unit && (
          <div className="absolute bottom-0 right-4 top-0 flex items-center">
            <Text
              size="bodyRegular"
              className={cn(" text-new-dark-3", unitClassName)}
            >
              {unit}
            </Text>
          </div>
        )}
        {isHiddenMode && (
          <button
            type="button"
            className="absolute bottom-0 right-4 top-0 flex items-center"
            onClick={() => setValueIsShow(!valueIsDisplay)}
          >
            {valueIsDisplay ? (
              <LuEye className="text-xl font-bold text-new-dark-1" />
            ) : (
              <LuEyeOff className="text-xl font-bold text-new-dark-1" />
            )}
          </button>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
